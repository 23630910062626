.App {
  text-align: left;
  font-family: 'Roboto Mono', monospace;
}

.Header {
  margin: 20px;
  font-size: 50px;
  font-weight: bold;
}

@keyframes flicker {
  0% {
    opacity: 0.0
  }

  50% {
    opacity: 1.0
  }
}

.About {
  animation: flicker 100ms steps(1, end) 0s 4 normal;
  margin: 20px;
}

.Links {
  margin: 20px;
}