.runalytics {
    margin: 30px;
    font-family: 'Roboto Mono', monospace;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.narrow {
    flex: 1;
}

.wide {
    flex: 2;
}

.bold {
    font-weight: bold;
}