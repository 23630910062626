.hangboard {
    margin: 30px;
    font-family: 'Roboto Mono', monospace;
}

p {
    margin: 0px;
    margin-bottom: 10px;
}

.time {
    font-weight: bold;
    display: inline-block;
    padding: 10px;
}

.active {
    background-color: rgb(228, 139, 139);
}

.inactive {
    background-color: rgb(136, 180, 224);
}