.patterns {
    margin: 30px;
    font-family: 'Roboto Mono', monospace;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 10px
}