.chess {
    margin: 30px;
    font-family: 'Roboto Mono', monospace;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px;
}

.board {
    display: flex;
    justify-content: center;
}


.narrow {
    flex: 1;
}

.wide {
    flex: 2;
}

p {
    margin: 5px;
}

button {
    margin: 10px;
}

.fen {
    background-color: black;
    color: white;
    font-weight: bold;
    padding: 10px;
    border: 10px;
    margin: 10px;
}