.photography {
    margin: 30px;
    font-family: 'Roboto Mono', monospace;
    max-width: 60%;
    display: block;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}

img {
    max-width: 100%;
    display: block;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    max-height: 80vh;
}

.caption {
    margin-top: 10px;
    padding-bottom: 60px;
    color: gray;
    text-align: center;
}

.loading {
    text-align: center;
    color: gray;
    margin-top: 30px;
}
